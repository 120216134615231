@use '@queries' as *;
@use '@content' as *;
.container {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  max-width: 100%;
  position: sticky;
  top: 300px; /* Positioned below the breadcrumbs */
  height: fit-content;
  max-height: calc(
    100vh - 320px
  ); /* Allow scrolling if the filter gets too tall */
  overflow-y: auto;
  @include hideScrollbar;

  @include min-1440-break {
    max-width: 100%;
  }
}
