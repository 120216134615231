@use '@content' as *;

.cart-mobile {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  .wrapper {
    @include mainContainerStyles;
    max-width: 1023px;
  }
}
