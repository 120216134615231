@use '@button' as *;
@use '@typography' as *;
@use '@fixed-size' as *;
@use '@queries' as *;

.cart {
  display: flex;
  gap: 24px;
  align-items: flex-start;
  padding: 18px 0 60px 0;

  @include min-1440-break {
    gap: 32px;
  }

  @include min-1920-break {
    padding: 24px 0 60px 0;
  }
}
