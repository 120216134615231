@use '@queries' as *;
.badge {
  position: absolute;

  &.one-number {
    top: 0;
    right: 0;
  }

  &.two-numbers {
    top: 0;
    right: -8px;

    @include min-1440-break {
      right: -5px;
    }
  }

  &.three-numbers {
    top: 0;
    right: -15px;

    @include min-1440-break {
      right: -12px;
    }
  }
}

.badge {
  position: absolute;

  &.mobile {
    &.one-number {
      top: 0;
      right: -50%;

      @include min-428-break {
        right: -30%;
      }
    }

    &.two-numbers {
      top: 0;
      right: -60%;

      @include min-428-break {
        right: -50%;
      }
    }

    &.three-numbers {
      top: 0;
      right: -95%;

      @include min-428-break {
        right: -70%;
      }
    }
  }
}
